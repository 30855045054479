import React from 'react';
import {Page} from './components/Page';

const carletonLink = "https://carleton.ca/"

function App() {

  return (
    <div className="App">
      <Page></Page>
    </div>
  );
}

export default App;
